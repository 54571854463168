import React, { useEffect, useState } from "react";
import Lamp from "./Lamp";

function HeroSection({ pageNumber }) {
  const [lightsOut, setLightsOut] = useState(true);
  useEffect(() => {
    if (pageNumber === 0) {
      setTimeout(() => {
        setLightsOut(false);
      }, 1000);
    } else {
      setLightsOut(true);
    }
  }, [pageNumber]);
  const handleToggleLight =()=>{
    setLightsOut(!lightsOut)
  }
  return (
    <div className="main bg-slate-600 h-full w-full dark-room flex flex-col text-white justify-center items-center relative gap-4 overflow-hidden">
      <Lamp lightsOut={lightsOut} handleToggleLight={handleToggleLight}/>
      <div className="flex justify-center items-center absolute w-full h-full z-10 dark-room overflow-hidden"></div>
      <div
        className={
          "flex justify-center items-center absolute w-full h-full z-10  overflow-hidden" +
          (lightsOut ? " light-out" : null)
        }
      ></div>
      <h1 className="gasoek-one-regular text-5xl  overflow-hidden">Hi, I'm Samuel PK</h1>
      <p className="text-lg  overflow-hidden subtext">
        I'm a{" "}
        <span className="text-[#61674e] bg-white px-2 mr-2  overflow-hidden">
          full-stack developer
        </span>
        , who loves making useful things!
      </p>
    </div>
  );
}

export default HeroSection;
