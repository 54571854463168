import { GitHub, Mail, Phone } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Lamp from "./Lamp";

function ContactSection({ pageNumber }) {
  const [lightsOut, setLightsOut] = useState(true);
  useEffect(() => {
    if (pageNumber === 2) {
      setTimeout(() => {
        setLightsOut(false);
      }, 1000);
    } else {
      setLightsOut(true);
    }
  }, [pageNumber]);
  const handleToggleLight =()=>{
    setLightsOut(!lightsOut)
  }
  return (
    <div className=" bg-slate-600 h-full w-full dark-room flex flex-col text-white justify-center items-center relative gap-4 overflow-hidden">
      <Lamp lightsOut={lightsOut}  handleToggleLight={handleToggleLight}/>
      <div className="flex justify-center items-center absolute w-full h-full z-10 dark-room overflow-hidden"></div>
      <div
        className={
          "flex justify-center items-center absolute w-full h-full z-10  overflow-hidden" +
          (lightsOut ? " light-out" : null)
        }
      ></div>
      <h1 className="gasoek-one-regular text-5xl ">Contact Me</h1>

      <div className="flex gap-10 wrapper-mobile">
        <div className="flex gap-2">
          <Mail /> <p>samuelpkelbiso@gmail.com</p>
        </div>
        <h1 className="divider">|</h1>{" "}
        <div className={"flex gap-2 " + (lightsOut ? null : " z-30")}>
          <Phone />

          <p>
            {" "}
            <a className="text-[inherit]" href="tel:+251986229229">+2519 86 15 32 45 </a>
          </p>
        </div>{" "}
        <h1 className="divider">|</h1>
        <div className="flex gap-2">
          <GitHub />
          <p>@sampk7</p>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
