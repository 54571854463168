import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import React from "react";

function Navigation({ pageNumber, handleScrollUp, handleScrollDown }) {
  return (
    <div className="rounded-xl bg-[#61674e] shadow-md flex flex-col p-2 gap-4 justify-center items-center absolute right-4 top-[45%] z-30 navigation ">
      <div
        className={
          pageNumber == 0
            ? ""
            : "cursor-pointer  active:-inset-y-[1px] relative"
        }
        onClick={handleScrollUp}
      >
        <ArrowUpward sx={{ color: pageNumber === 0 ? "gray" : null }} />
      </div>
      <div className="rounded-full  navigation-bubles   p-1 bg-black-- text-[#61674e] flex justify-center items-center  flex-col gap-2">
        {Array.from({length:3},(v,i,k)=>i).map((page, ) => {
          return (
            <div
            
              className={
                "bg-black rounded-full" + (page !== pageNumber ? " w-4 h-4 " : " w-6 h-6")
              }
            ></div>
          );
        })}
      </div>
      <div
        className={
          pageNumber == 2 ? "" : "cursor-pointer  active:inset-y-[1px] relative"
        }
        onClick={handleScrollDown}
      >
        <ArrowDownward sx={{ color: pageNumber === 2 ? "gray" : null }} />
      </div>
    </div>
  );
}

export default Navigation;
