import React, { useEffect, useRef, useState } from "react";
import Lamp from "./Lamp";
import useWindowDimensions from "../hooks/useWindowDimensions.js";

function ExpertiseSection({
  pageNumber,
  handleCardClicked,
  handleCardReleased,
}) {
  const [lightsOut, setLightsOut] = useState(true);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (pageNumber === 1) {
      setTimeout(() => {
        setLightsOut(false);
      }, 1000);
    } else {
      setLightsOut(true);
    }
  }, [pageNumber]);
  const handleToggleLight = () => {
    setLightsOut(!lightsOut);
  };
  return (
    <div className=" bg-slate-600 h-full w-full dark-room flex flex-col text-white justify-center items-center relative gap-10 overflow-hidden">
      <Lamp lightsOut={lightsOut} handleToggleLight={handleToggleLight} />
      <div className="flex justify-center items-center absolute w-full h-full z-10 dark-room overflow-hidden"></div>
      <div
        className={
          "flex justify-center items-center absolute w-full h-full z-10  overflow-hidden" +
          (lightsOut ? " light-out" : null)
        }
      ></div>
      <h1 className="gasoek-one-regular text-5xl ">Experiance Areas</h1>
      <div
        onMouseDown={width > 700 ? null : handleCardClicked}
        onMouseEnter={width > 700 ? null : handleCardClicked}
        onTouchStart={width > 700 ? null : handleCardClicked}
        onMouseUp={width > 700 ? null : handleCardReleased}
        onMouseLeave={width > 700 ? null : handleCardReleased}
        onTouchEnd={width > 700 ? null : handleCardReleased}
        className={
          "cards flex gap-8 overflow-auto max-w-[100dvw]  px-4" +
          (lightsOut ? null : " z-20")
        }
      >
        <div className="card shadow-lg min-w-80 max-w-80  min-h-80 border--  rounded-full  overflow-hidden border-white relative">
          <div className="dark-room flex justify-center items-center absolute w-full h-full fadein ">
            <p className="text-3xl font-bold text-white drop-shadow-lg w-full --third-color py-4 text-center">{'ERP & CMS'}</p>
          </div>
          <div
            className="w-full h-full bg-cover bg-center"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/dtknehocq/image/upload/v1711539472/Portifolio-Sampk7/Screenshot_2024-03-27_143034_ebvjuk.png)",
            }}
          />
        </div>
        <div className="card drop-shadow-lg min-w-80 max-w-80  min-h-80 border--   rounded-full  overflow-hidden border-white relative">
          <div className="dark-room flex justify-center items-center absolute w-full h-full fadein ">
            <p className="text-3xl font-bold text-white drop-shadow-lg w-full --third-color py-4 text-center">
              Website
            </p>
          </div>
          <div
            className="w-full h-full bg-cover bg-center"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/dtknehocq/image/upload/v1711540425/Portifolio-Sampk7/Screenshot_2024-03-27_145306_njnqpb.png)",
            }}
          />
        </div>
        <div className="card   min-w-80 max-w-80  min-h-80 border--  rounded-full overflow-hidden   border-white relative ">
          <div className="dark-room flex justify-center items-center absolute w-full h-full fadein ">
            <p className="text-3xl font-bold text-white drop-shadow-lg  w-full --third-color py-4 text-center">
              Data-Analytics
            </p>
          </div>
          <div
            className="w-full h-full bg-cover bg-center  "
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/dtknehocq/image/upload/v1711549109/Portifolio-Sampk7/Screenshot_2024-03-27_150122_sp7wgs.png)",
            }}
          />
        </div>
        {/* <div className="card w-96 h-96 border border-white"></div>
        <div className="card w-96 h-96 border border-white"></div> */}
      </div>
    </div>
  );
}

export default ExpertiseSection;
