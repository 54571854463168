import ReactPageScroller from "react-page-scroller";
import HeroSection from "./components/HeroSection";
import ExpertiseSection from "./components/ExpertiseSection";
import ContactSection from "./components/ContactSection";
import { useState } from "react";
import Navigation from "./components/Navigation";
import { GlobalStyles } from "@mui/material";
function App() {
  const [pageNumber, setNumberScroll] = useState(0);
  const [isUncrollable, setIsUnscrollable] = useState(false);
  const handlePageScroll = (index) => {
    setNumberScroll(index);
  };
  const handleScroll = (index) => {
    if (index === "down") {
      setNumberScroll(pageNumber === 0 ? 1 : pageNumber === 1 ? 2 : 2);
    } else {
      setNumberScroll(pageNumber === 2 ? 1 : pageNumber === 1 ? 0 : 0);
    }
  };
  const handleExperianceCardIsCLicked = () => {
    console.log("preseed");
    setIsUnscrollable(true);
  };
  const handleExperianceCardIsReleased = () => {
    console.log("releseased");

    setIsUnscrollable(false);
  };
  return (
    <div className="App">
      <GlobalStyles
        styles={{
          scrollbarColor: "#6b6b6b #2b2b2b",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#959595", //scroll box color
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            minHeight: 24,
            backgroundColor: "#6b6b6b", //scroll bar color

            border: "2px solid #6b6b6b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              border: "1px solid rgb(75, 91, 127)",
              backgroundColor: "#6b6b6f", //scroll bar color when hovered
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        }}
      />

      <Navigation
        pageNumber={pageNumber}
        handleScrollUp={() => handleScroll("up")}
        handleScrollDown={() => handleScroll("down")}
      />
      <ReactPageScroller
        blockScrollDown={isUncrollable}
        blockScrollUp={isUncrollable}
        customPageNumber={pageNumber}
        renderAllPagesOnFirstRender
        onBeforePageScroll={handlePageScroll}
      >
        <HeroSection pageNumber={pageNumber} />
        <ExpertiseSection
          handleCardClicked={handleExperianceCardIsCLicked}
          handleCardReleased={handleExperianceCardIsReleased}
          pageNumber={pageNumber}
          is
        />
        <ContactSection pageNumber={pageNumber} />
      </ReactPageScroller>
    </div>
  );
}

export default App;
