import React from "react";

function Lamp({ lightsOut, handleToggleLight }) {
  return (
    <div className="lam-parent flex justify-center items-start absolute w-full h-full  z-20">
      {lightsOut ? (
        <div className="h-6 w-80 lam-off z-10 drop-shadow-xl flex justify-center items-center">
          <div
            onClick={handleToggleLight}
            className=" cursor-pointer rounded-full w-3 h-3 bg-black active:-inset-y-[1px]  relative"
          >

          </div>
        </div>
      ) : (
        <div className="  drop-shadow-xl h-6 w-80 lam-on z-10  flex justify-center items-center">
          <div
            onClick={handleToggleLight}
            className=" cursor-pointer rounded-full w-3 h-3 bg-black active:-inset-y-[1px]  relative flex justify-center items-center"
          >
            <div className="rounded-full bg-red-700 w-[1px] h-[1px]"></div>
          </div>
        </div>
      )}
      {lightsOut ? null : (
        <div className="light-flare w-[90rem] h-[30rem] absolute top-[-15rem]"></div>
      )}
    </div>
  );
}

export default Lamp;
